import axios from 'axios';

// Configura l'URL base per tutte le richieste
axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/`;

// Aggiungi un interceptor per le richieste
axios.interceptors.request.use(
  function (config) {
    // Puoi personalizzare la configurazione della richiesta qui
    // Ad esempio, aggiungi un'intestazione di autorizzazione
    config.headers.Authorization = 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN;
    return config;
  },
  function (error) {
    // Gestisci errori di richiesta
    return Promise.reject(error);
  }
);

// Aggiungi un interceptor per le risposte
axios.interceptors.response.use(
  function (response) {
    // Puoi personalizzare la gestione delle risposte qui
    // Ad esempio, gestire gli errori globali
    return response;
  },
  function (error) {
    // Gestisci errori di risposta
    return Promise.reject(error);
  }
);

export default axios;





