export const getDayNameFromDayNumber = (dayNumber: number): string => {
    switch (dayNumber) {
        case 1:
            return "lunedi"
        case 2:
            return "martedi"
        case 3:
            return "mercoledi"
        case 4:
            return "giovedi"
        case 5:
            return "venerdi"
        case 6:
            return "sabato"
        case 7:
            return "domenica"
        default:
            return "";
    }
}

export function getWeeksOfYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // Inizializza la data al primo giorno dell'anno
    const startDate = new Date(currentYear, 0, 1);

    // Ottieni il giorno della settimana del primo giorno dell'anno
    const startDayOfWeek = startDate.getDay();

    // Calcola il numero di giorni da aggiungere al primo giorno per ottenere il primo lunedì
    const daysToAdd = startDayOfWeek === 0 ? 1 : 8 - startDayOfWeek;

    // Aggiungi giorni per ottenere il primo lunedì
    startDate.setDate(startDate.getDate() + daysToAdd);

    // Inizializza un array per contenere le settimane
    const weeks = [];

    // Loop attraverso l'anno e aggiungi le settimane all'array
    while (startDate.getFullYear() === currentYear) {
        const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000); // Aggiungi 6 giorni per ottenere la fine della settimana
        weeks.push({
            start: new Date(startDate),
            end: new Date(endDate),
        });

        // Passa alla settimana successiva
        startDate.setDate(startDate.getDate() + 7);
    }

    return weeks;
}

export function getCurrentWeekIndex() {
    try {
        const weeksOfYear = getWeeksOfYear();
        const currentDate = new Date();
    
        return weeksOfYear.findIndex((week) => {
            return currentDate >= week.start && currentDate <= week.end;
        });
    } catch (error) {
        return -1;
    }
  }

  export const capitalize = (s:string) => s?.split(" ").map(currS => `${currS?.charAt(0).toUpperCase()}${currS?.slice(1)}`)?.join(" ");