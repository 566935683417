import { TStrapiResponse } from "../types/TStrapiResponse";
import { TPalinsestoContenuti } from "../types/TPalinsestoContenuti";
import axios from "./interceptor"
import { TStrapiFilter } from "../types/TStrapiFilter";
import { TPalinsestoLayout } from "../types/TPalinsestoLayout";
const qs = require('qs');
const FIRST_PAGE_STRAPI = 1
const MAX_LIMIT_STRAPI = 100
const getQueryFilters = (filters?:TStrapiFilter): string => {
  let filter = "";
  if(filters?.populate)
    filter += typeof filters?.populate==="boolean" ? `populate=*` : `${filters?.populate?.split(",").map(it => `${it}=*`).join("&")}`

  const query = qs.stringify({
    filters: {
      ...(filters?.building_id ? { building: {id: {$eq: filters?.building_id}} } : {}),
      ...(filters?.dispositivo ? { dispositivo: {id: {$eq: filters?.dispositivo}} } : {}),
      ...(filters?.digital_signage ? { digital_signage: {id: {$eq: filters?.digital_signage}} } : {}),
      ...(filters?.led_wall ? { led_wall: {id: {$eq: filters?.led_wall}} } : {}),
      /**
				 * Prendo i contenuti che hanno la data di inizio minore uguale alla data di fine del filtro e
				 * la data di fine maggiore uguale alla data di inizio del filtro
				 */
				...(filters?.end_date
					? { start_date: { $lte: filters?.end_date } }
					: {}),
				...(filters?.start_date
					? { end_date: { $gte: filters?.start_date } }
					: {}),
      },
    }, {
      encodeValuesOnly: true,
    });
  filter+= `${filter?.length ? "&" : ""}${query}`
  return filter;
}

const getPalinsestoContenuti = async (filters?:TStrapiFilter):Promise<TStrapiResponse<TPalinsestoContenuti>> => {
  let result:TStrapiResponse<TPalinsestoContenuti> = {data:[], meta: null};
  const firstResponse: TStrapiResponse<TPalinsestoContenuti> = await(await axios.get(`scheduled-contents?${getQueryFilters(filters)}&pagination[pageSize]=${MAX_LIMIT_STRAPI}&pagination[page]=${FIRST_PAGE_STRAPI}`))?.data;
  result.data = result.data.concat(firstResponse.data)
  if(Number(firstResponse.meta?.pagination?.pageCount) > 1){
    await Promise.all([...Array.from(Array(firstResponse.meta!.pagination.pageCount - 1).keys())].map(async (item, indexItem) => {
      result.data = result.data.concat(await(await axios.get(`scheduled-contents?${getQueryFilters(filters)}&pagination[pageSize]=${MAX_LIMIT_STRAPI}&pagination[page]=${(FIRST_PAGE_STRAPI+indexItem+1)}`))?.data)
    }));
  }
  return result;
}
const getPalinsestoLayouts = async (filters?:TStrapiFilter):Promise<TStrapiResponse<TPalinsestoLayout>> => {
  let result:TStrapiResponse<TPalinsestoLayout> = {data:[], meta: null};
  const firstResponse: TStrapiResponse<TPalinsestoLayout> = await(await axios.get(`scheduled-layouts?${getQueryFilters(filters)}&pagination[pageSize]=${MAX_LIMIT_STRAPI}&pagination[page]=${FIRST_PAGE_STRAPI}`))?.data;
  result.data = result.data.concat(firstResponse.data)
  if(Number(firstResponse.meta?.pagination?.pageCount) > 1){
    await Promise.all([...Array.from(Array(firstResponse.meta!.pagination.pageCount - 1).keys())].map(async (item, indexItem) => {
      result.data = result.data.concat(await(await axios.get(`scheduled-layouts?${getQueryFilters(filters)}&pagination[pageSize]=${MAX_LIMIT_STRAPI}&pagination[page]=${(FIRST_PAGE_STRAPI+indexItem+1)}`))?.data)
    }));
  }
  return result;
}
const getBuildings = async (filters?:TStrapiFilter):Promise<TStrapiResponse<any>> => {
    return await(await axios.get(`buildings?${getQueryFilters(filters)}`))?.data;
}
const getLedWalls = async (filters?:TStrapiFilter):Promise<TStrapiResponse<any>> => {
  return await(await axios.get(`led-walls?${getQueryFilters(filters)}`))?.data;
}
const getDss = async (filters?:TStrapiFilter):Promise<TStrapiResponse<any>> => {
  return await(await axios.get(`devices?${getQueryFilters(filters)}`))?.data;
}

export { getPalinsestoContenuti, getPalinsestoLayouts, getBuildings, getLedWalls, getDss }