import moment from "moment";
import { TPalinsestoContenuti } from "../types/TPalinsestoContenuti";
import { TPalinsestoLayout } from "../types/TPalinsestoLayout";
import { ALTEZZA_CALENDAR_CONTENT, CMS_SINGLE_PALINSTESTO_CONTENUTI_ROUTE, MINUTI_IN_UN_GIORNO } from "../utils/consts";
import {
    Popover,
    PopoverHandler,
    PopoverContent,
} from "@material-tailwind/react";

type TBlockEvent = {
    item: TPalinsestoContenuti | TPalinsestoLayout
    zIndex: number;
    type: "layout" | "content"
}
type TBlockEventChild = {
    title: string
}
const BlockEventChild = (props: TBlockEventChild) => {
    return (
        <span className="bg-white rounded-[4px] py-1 text-[8px] px-1 leading-[9px] text-[#22262E] my-1 break-all font-semibold"
            style={{ width: "calc(100% - 8px)", marginLeft: 4 }}>
            {props?.title} 
        </span>
    )
}
const BlockEvent = (props: TBlockEvent) => {
    const minutiCurrentPalinsesto = moment.duration(moment(props.item.ends_at, "HH:mm").diff(moment(props.item.starts_at, "HH:mm"))).asMinutes();
    const minutiFromCurrentPalinsesto = moment.duration(moment(props.item.starts_at, "HH:mm").diff(moment("00:00", "HH:mm"))).asMinutes()
    const rangeTime = `${moment(props.item.starts_at, "HH:mm").format("HH:mm")} - ${moment(props.item.ends_at, "HH:mm").format("HH:mm")}`;

    return (
        <Popover placement="right-start" animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                }} >
            <PopoverHandler>
                <div className={`absolute ${props.type === "content" ? "bg-[#5430E8] hover:bg-[#4b2bd0]" : "bg-[#3259E8] hover:bg-[#2d50d0]"} 
            cursor-pointer transition-all  rounded`}
                    style={{
                        height: (ALTEZZA_CALENDAR_CONTENT * minutiCurrentPalinsesto) / MINUTI_IN_UN_GIORNO,
                        width: "calc(100% - 8px)", marginLeft: 4,
                        zIndex: props.zIndex, top: (ALTEZZA_CALENDAR_CONTENT * minutiFromCurrentPalinsesto) / MINUTI_IN_UN_GIORNO
                    }}>
                    <div className="p-1">
                        <span className="block text-white text-[12px] leading-[14px] font-semibold">{props.item.name}</span>
                        <span className="block text-white text-[9px] leading-[10px] font-normal mt-1">{rangeTime}</span>
                    </div>
                    <div className="flex flex-col w-full">
                        {
                            props?.type === "content" ?
                                (props?.item as TPalinsestoContenuti)?.templates?.filter(t => t?.template?.data?.attributes?.name)?.map(t => <BlockEventChild title={t?.template?.data?.attributes?.name} />)
                                :
                                <BlockEventChild title={(props?.item as TPalinsestoLayout)?.layout?.data?.attributes?.name} />
                        }
                    </div>
                </div>
            </PopoverHandler>
            <PopoverContent className="min-w-[390px] max-w-[390px] z-[999999] rounded-lg p-4 shadow-modal border-[1px] border-[#E2E5EB] border-solid" placeholder={""} >
                <div className="">
                    {/* <button onClick={()=>{

                    }} className="absolute w-[12px] h-[12px] top-[17px] right-[17px]">
                        <img src="./close-icon.png"/>
                    </button> */}
                    <p className="font-bold text-xl leading-[22px] text-textColor">{props.item.name}</p>
                    <p className="text-textColor font-normal text-xs leading-[14px] mb-4 mt-1">{rangeTime}</p>
                    {
                            props?.type === "content" ?
                                <div>
                                    <div className="flex flex-col mb-4">
                                        <label className="font-bold text-xs leading-[14px] text-textColor mb-2">{"Giorno"}</label>
                                        <input className="h-[33px] font-semibold text-textColor text-xs leading-[14px]" value={props?.item?.day}/>
                                    </div>

                                    <div className="flex flex-col mb-4">
                                        <label className="font-bold text-xs leading-[14px] text-textColor mb-2">{"Dispositivo"}</label>
                                        <input className="h-[33px] font-semibold text-textColor text-xs leading-[14px]" 
                                        value={(props?.item as TPalinsestoContenuti).dispositivo?.data?.attributes?.name}/>
                                    </div>

                                    <div className="flex flex-col mb-4">
                                        <label className="font-bold text-xs leading-[14px] text-textColor mb-2">{"Contenuti"}</label>
                                        {
                                            (props?.item as TPalinsestoContenuti)?.templates?.filter(t => t?.template?.data?.attributes?.name)?.map(t => 
                                                <input className="h-[33px] bg-[#E2E5EB] font-semibold text-textColor text-xs leading-[14px] mb-1 border-none" 
                                                value={t?.template?.data?.attributes?.name}/>)
                                        }
                                    </div>
                                </div>
                            :
                            <div>
                                <div className="flex flex-col mb-4">
                                    <label className="font-bold text-xs leading-[14px] text-textColor mb-2">{"Layout"}</label>
                                    <input className="h-[33px] font-semibold text-textColor text-xs leading-[14px]" value={(props?.item as TPalinsestoLayout)?.layout?.data?.attributes?.name}/>
                                </div>

                                <div className="flex flex-col mb-4">
                                    <label className="font-bold text-xs leading-[14px] text-textColor mb-2">{"Led wall"}</label>
                                    <input className="h-[33px] font-semibold text-textColor text-xs leading-[14px]" value={(props?.item as TPalinsestoLayout)?.led_wall?.data?.attributes?.name}/>
                                </div>

                                <div className="flex flex-col mb-4">
                                        <label className="font-bold text-xs leading-[14px] text-textColor mb-2">{"Associazioni area-dispositivo"}</label>
                                        {
                                            (props?.item as TPalinsestoLayout)?.dev_area?.map(t => 
                                                <input className="h-[33px] bg-[#E2E5EB] font-semibold text-textColor text-xs leading-[14px] mb-1 border-none" 
                                                value={t?.Nome_associazione}/>)
                                        }
                                    </div>
                            </div>
					}
					<a href={`${process.env.REACT_APP_BACKEND_URL}/${CMS_SINGLE_PALINSTESTO_CONTENUTI_ROUTE}/${props.item.id}`} target="_blank" rel="noopener">{"VISUALIZZA NEL CMS"}</a>
                </div>
            </PopoverContent>
        </Popover>
    )

}

export default BlockEvent;