import { useEffect, useState } from "react";
import { TSelectOption } from "./types/TSelectOption";
import { getBuildings, getDss, getLedWalls, getPalinsestoContenuti, getPalinsestoLayouts } from "./utils/network";
import { TBuilding } from "./types/TBuilding";
import { TLedWall } from "./types/TLedWall";
import { TDs } from "./types/TDs";
import { capitalize, getCurrentWeekIndex, getWeeksOfYear } from "./utils/utils";
import moment from "moment"
import 'moment/locale/it'
import { TPalinsestoContenuti } from "./types/TPalinsestoContenuti";
import { TPalinsestoLayout } from "./types/TPalinsestoLayout";
import BlockEvent from "./components/BlockEvent";
import { ALTEZZA_CALENDAR } from "./utils/consts";
import Filter from "./components/Filter";
moment.locale('it')

function App() {
  const [buildings, setBuildings] = useState<TBuilding[]>([]);
  const [ledWalls, setLedWalls] = useState<TLedWall[]>([]);
  const [devices, setDevices] = useState<TDs[]>([]);

  const [building, setBuilding] = useState<number>();
  const [ledWall, setLedWall] = useState<number>();
  const [device, setDevice] = useState<number>();

  const weeks = getWeeksOfYear();
  const [indexWeek, setIndexWeek] = useState<number>(getCurrentWeekIndex() + 1);
  const [palinsestoContenutoByDay, setPalinsestoContenutoByDay] = useState<{ [key: string]: TPalinsestoContenuti[] }>({})
  const [palinsestoLayoutByDay, setPalinsestoLayoutByByDay] = useState<{ [key: string]: TPalinsestoLayout[] }>({})

  const getCurrentWeek = () => weeks[indexWeek - 1]

  useEffect(() => {
    getFilters();
  }, [])

  async function getFilters() {
    try {
      setBuildings((await getBuildings())?.data || [])
    } catch (error) {
      //TODO: Show error or retry btn
    }
  }

  useEffect(() => {
    if (!!device && !!ledWall && !!building && !!indexWeek) {
      getPalinsesto();
    }
  }, [device, ledWall, building, indexWeek])

  async function getPalinsesto() {
    //Get palinsesto contenuti
    try {
      const palinsestoContenuti = await getPalinsestoContenuti({ populate: "populate[templates][populate],populate[dispositivo]", dispositivo: device, start_date: moment(getCurrentWeek()?.start).format("yyyy-MM-DD"), end_date: moment(getCurrentWeek()?.end).format("yyyy-MM-DD") })
      let _palinsestoContenutoByDay: { [key: string]: any[] } = {}
      palinsestoContenuti.data
        ?.map((p) => {
          return (
            {
              ...p,
              start_date_datetime: moment(`${p.attributes.start_date} ${p.attributes.starts_at?.slice(0, 8)}`, "yyyy-MM-DD hh:mm:ss"),
              end_date_datetime: moment(`${p.attributes.end_date} ${p.attributes.ends_at?.slice(0, 8)}`, "yyyy-MM-DD hh:mm:ss"),
            })
        })
        .sort((p1, p2) => p1.start_date_datetime.diff(p2.start_date_datetime))
        .forEach((p) => {
          const keyDay = String(p.attributes.day).toLocaleLowerCase()
          if (!_palinsestoContenutoByDay[keyDay]) {
            _palinsestoContenutoByDay[keyDay] = []
          }
		  
		  _palinsestoContenutoByDay[keyDay].push({
			...p.attributes,
			id: p.id
		  })
		})
      setPalinsestoContenutoByDay(_palinsestoContenutoByDay)
    } catch (error) {
      //TODO: err
    }

    //Get palinsesto layout
    try {
      const palinsestoLayouts = await getPalinsestoLayouts({ populate: true, led_wall: ledWall, start_date: moment(getCurrentWeek()?.start).format("yyyy-MM-DD"), end_date: moment(getCurrentWeek()?.end).format("yyyy-MM-DD") })
      let _palinsestoLayoutByDay: { [key: string]: any[] } = {}
      palinsestoLayouts.data
        ?.map((p) => {
          return (
            {
              ...p,
              start_date_datetime: moment(`${p.attributes.start_date} ${p.attributes.starts_at?.slice(0, 8)}`, "yyyy-MM-DD hh:mm:ss"),
              end_date_datetime: moment(`${p.attributes.end_date} ${p.attributes.ends_at?.slice(0, 8)}`, "yyyy-MM-DD hh:mm:ss"),
            })
        })
        .sort((p1, p2) => p1.start_date_datetime.diff(p2.start_date_datetime))
        .forEach((p) => {
          const keyDay = String(p.attributes.day).toLocaleLowerCase()
          if (!_palinsestoLayoutByDay[keyDay]) {
            _palinsestoLayoutByDay[keyDay] = []
          }
          _palinsestoLayoutByDay[keyDay].push(p.attributes)
        })
      setPalinsestoLayoutByByDay(_palinsestoLayoutByDay)
    } catch (error) {
      //TODO: err
    }
  }

  return (
    <div>

      <div className="container mx-auto py-5">
        <div className="flex items-center justify-center">
          <img src="logo512.png" className="max-w-[200px]"/>
        </div>
        <div className="grid grid-cols-4 gap-8">

          <Filter label="Building" value={building} options={buildings?.map(s => {
            return ({ id: s?.id, name: s.attributes?.name })
          })} onChange={async (e) => {
            const selectedBuildingId = Number(e.target?.value);
            setBuilding(selectedBuildingId)
            setLedWall(0)
            setDevice(0)
            try {
              setLedWalls((await getLedWalls({ building_id: selectedBuildingId }))?.data || [])
            } catch (error) {
              //TODO: Show error or retry btn
            }
          }} />

          <Filter label="Sala" value={ledWall} disabled={!!!building} options={ledWalls?.map(s => {
            return ({ id: s?.id, name: s.attributes?.name })
          })} onChange={async (e) => {
            const selectedLedWallId = Number(e.target?.value);
            setLedWall(selectedLedWallId)
            setDevice(0)
            try {
              setDevices((await getDss({ led_wall: selectedLedWallId }))?.data || [])
            } catch (error) {
              //TODO: Show error or retry btn
            }
          }} />

          <Filter label="DS" value={device} disabled={!!!ledWall} options={devices?.map(s => {
            return ({ id: s?.id, name: s.attributes?.name })
          })} onChange={async (e) => {
            setDevice(Number(e.target?.value))
          }} />


          <Filter label="Settimana" type="calendar" value={indexWeek} disabled={!!!ledWall} options={weeks?.map((s, indexS) => {
            return ({ id: indexS + 1, name: `${capitalize(moment(s?.start).format("DD MMMM"))} - ${capitalize(moment(s?.end).format("DD MMMM"))}` })
          })} onChange={async (e) => {
            setIndexWeek(Number(e.target?.value))
          }} />

        </div>

        <div className="grid grid-cols-7 mt-16 relative">
          {
            Array.from(Array(7).keys()).map((currItem, indexCurrItem) => {
              const isCurrentDay = moment(getCurrentWeek()?.start).add(indexCurrItem, "days").format("DD-MM-yyyy") === moment()?.format("DD-MM-yyyy")
              return (
                <div className={`pb-8 flex flex-col items-center justify-center ${indexCurrItem + 1 != 7 ? "border-r-[0.5px] border-[#C1C1C1] border-solid" : ""}`}>
                  <span className={`text-lg ${isCurrentDay ? `font-bold text-primaryColor` : `font-normal text-textGray`}`}>{moment(getCurrentWeek()?.start).add(indexCurrItem, "days").format("ddd")?.toUpperCase().slice(0, 3)}</span>
                  <span className={`text-2xl ${isCurrentDay ? "font-extrabold text-primaryColor" : "font-semibold text-textColor"}`}>{moment(getCurrentWeek()?.start).add(indexCurrItem, "days").format("DD")}</span>
                </div>
              );
            })
          }
        </div>
        <div className="grid grid-cols-7 relative" style={{ height: ALTEZZA_CALENDAR }}>
          <div className="absolute h-full w-5 pt-[10px] left-[-30px] bottom-0 flex flex-col justify-between items-center"
          // style={{height:"calc(100% - 10px)"}}
          >
            {
              Array.from(Array(24).keys()).map((indexCurr) => {
                return (
                  <span className="flex items-center justify-center text-xl text-borderPrimary font-light">
                    <span>{`${indexCurr}`.length === 1 ? `0${indexCurr}` : indexCurr}</span>
                    <hr className="border-0 bg-borderPrimary h-[1px] w-[8px] ml-1" />
                  </span>
                )
              })
            }
          </div>
          {
            Array.from(Array(7).keys()).map((currItem, indexCurrItem) => {
              const currentDayKey = moment(getCurrentWeek()?.start).add(indexCurrItem, "days").format("dddd");
              const currentPalinsestoContenuti = palinsestoContenutoByDay?.[currentDayKey]
              const currentPalinsestoLayout = palinsestoLayoutByDay?.[currentDayKey]
              return (
                <div className={indexCurrItem + 1 != 7 ? "border-r-[0.5px] border-[#C1C1C1] border-solid" : ""}>
                  <div className="grid grid-cols-2 gap-x-1 mx-1 h-full">
                    {
                      Array.from(Array(2).keys()).map((col, indexColumn) => {
                        const isLayout = indexColumn === 0
                        const isContent = indexColumn === 1

                        return (
                          <div className={`rounded h-full w-full ${isLayout ? "bg-[#E0EDFF]" : "bg-[#E7E0FF]"}`}>
                            <span className="block text-xs font-semibold text-textColor mx-2 my-2">{isLayout ? "Layout" : "Contenuti"}</span>
                            <div className="relative h-full" style={{ height: "calc(100% - 20px)" }}>
                              {
                                isContent ?
                                  currentPalinsestoContenuti?.map((p, indexP) => <BlockEvent item={p} zIndex={indexP + 1} type="content" />)
                                  :
                                  currentPalinsestoLayout?.map((p, indexP) => <BlockEvent item={p} zIndex={indexP + 1} type="layout" />)
                              }
                            </div>
                          </div>
                        )
                      })
                    }

                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

    </div>
  );
}

export default App;
