type TFilter = {
    label: string
    value:any;
    placeholder?:string;
    disabled?: boolean;
    type?: "select" | "calendar" | "readOnly";
    onChange: (e:React.ChangeEvent<HTMLSelectElement>) => void;
    options:{id:any, name: string}[];
}
const Filter = ({label, value, onChange, options, placeholder, disabled, type}:TFilter) => {
    return(
        <div className="flex flex-col">
              <label className="font-semibold text-sm leading-5 mb-1" htmlFor={`idselect-${label}`}>{label}</label>
              <select className={`${type || ""}`} id={`idselect-${label}`} disabled={disabled} value={value} onChange={async(e)=>{ 
                onChange(e);
               }}>
                  <option value="">{placeholder || "Seleziona un valore"}</option>
                  {options?.map(o => <option value={o?.id}>{o?.name}</option>)}
              </select>
            </div>
    )
}

export default Filter;